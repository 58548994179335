import { createReducer, on } from "@ngrx/store";
import { routeActions } from "../actions";
import { IRoute } from "../models";

export const initialRoute = <IRoute>{url: 'menu'};

export const routeReducer = createReducer(
  initialRoute,
  on(routeActions.setRoute, (state, routeObj) => {
    return {
      ...state,
      url: routeObj.url
    }
  }),
  on(routeActions.getRoute, (state) => {
    return { ...state };
  }),
  on(routeActions.clearRoute, (state) => {
    return <IRoute>{}
  })
);