import { createReducer, on } from "@ngrx/store";
import { IUsuario } from "../models";
import { usuarioActions } from "../actions";

export const initialUsuario = <IUsuario>{};

export const usuarioReducer = createReducer(
  initialUsuario,
  on(usuarioActions.setUsuario, (state, usuarioObj) => {
    return {
      ...state,
      id: usuarioObj.id,
      nome: usuarioObj.nome,
      email: usuarioObj.email,
      id_cliente: usuarioObj.id_cliente,
      token: usuarioObj.token
    }
  }),
  on(usuarioActions.getUsuario, (state) => {
    return { ...state };
  }),
  on(usuarioActions.clearUsuario, (state) => {
    return <IUsuario>{}
  })
);