import { createAction, props } from "@ngrx/store";
import { IRoute } from "../models";

const setRoute = createAction('[ Route ] Armazena rota', props<IRoute>());
const getRoute = createAction('[ Route ] Busca rota');
const clearRoute = createAction('[ Route ] Limpa rota');

export const routeActions = {
  setRoute,
  getRoute,
  clearRoute
}