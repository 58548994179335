import { createAction, props } from "@ngrx/store";
import { ICondominio } from "../models";

const setCondominios = createAction('[ Condominio ] Armazena condomínios', props<{condominios: ICondominio[], selecionado: ICondominio}>());
const getCondominios = createAction('[ Condominio ] Busca condomínios');
const clearCondominios = createAction('[ Condominio ] Limpa condomínios');

export const condominioActions = {
  setCondominios,
  getCondominios,
  clearCondominios
}