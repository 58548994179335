import { createReducer, on } from "@ngrx/store";
import { condominioActions } from "../actions";
import { ICondominio } from "../models";

export interface CondominioState {
  condominios: ICondominio[],
  selecionado: ICondominio
}

export const initialState: CondominioState = {
  condominios: [],
  selecionado: <ICondominio>{}
}

export const condominioReducer = createReducer(
  initialState,
  on(condominioActions.setCondominios, (state, condominioObj) => {
    return {
      ...state,
      condominios: condominioObj.condominios,
      selecionado: condominioObj.selecionado
    }
  }),
  on(condominioActions.getCondominios, (state) => {
    return { ...state }
  }),
  on(condominioActions.clearCondominios, (state) => {
    return {
      ...state,
      condominios: [],
      selecionado: <ICondominio>{}
    }
  })
);