import { ActionReducerMap } from "@ngrx/store";
import { IAppState } from "../state";
import { usuarioReducer } from "./usuario.reducer";
import { condominioReducer } from "./condominio.reducer";
import { routeReducer } from "./route.reducer";

export const appReducers: ActionReducerMap<IAppState> = {
  usuario: usuarioReducer,
  condominios: condominioReducer,
  route: routeReducer
}