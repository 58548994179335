import { createAction, props } from "@ngrx/store";
import { IUsuario } from "../models";

const setUsuario = createAction('[ Usuario ] Armazena usuário', props<IUsuario>());
const getUsuario = createAction('[ Usuario ] Busca usuário');
const clearUsuario = createAction('[ Usuario ] Limpa usuário');

export const usuarioActions = {
  setUsuario,
  getUsuario,
  clearUsuario
}